.place {
    &Category {
        &__title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex-direction: column;
        }
    }
    width: 100%;
    background: #202327;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 12px;
        padding-right: 12px;
    }
    &__back {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & a {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: #cfb28b;
        }
    }
    &__title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
    }
    &__nav {
        margin: 22px 0 0 0;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
            width: 0;
        }
        &-item {
            padding: 3px 14px;
            background: #cfb28b;
            border-radius: 14px;
            margin: 0 8px 0 0;
            &:last-child {
                margin: 0 0 0 0;
            }
        }
    }
    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        cursor: pointer;
        width: max-content;
        & p {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: #ffffff;
        }
        &-active {
            padding-left: 22px;
            position: relative;
            &::after {
                content: "";
                margin-left: 4px;
                display: block;
                width: 14px;
                height: 11px;
                background: url("../../icon/catalog/close.svg") no-repeat;
                background-size: 14px 11px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                border-radius: 3px;
                z-index: 2;
                transition: opacity 0.1s linear;
            }
        }
        &-deactive {
            background: #202327;
            border: #cfb28b 1px solid;
            & p {
                color: #cfb28b;
            }
        }
        & p {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: #ffffff;
        }
    }
    &__a {
        background: #202327;
        border: #cfb28b 1px solid;
        border-color: #cfb28b;
        & p {
            color: #cfb28b;
        }
    }
    &__checkbox {
        margin: 13px auto 0 0;
        &-label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 0 0 36px;
            position: relative;
            cursor: pointer;
            &::before {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                background: #2f3337;
                border: 1px solid #8d9196;
                border-radius: 3px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                transition: background 0.1s linear, border 0.1s linear;
            }
            &::after {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                background: url("../../icon/selection/check.svg") no-repeat
                    center;
                background-size: 12px 12px;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #8d9196;
                z-index: 2;
                transition: opacity 0.1s linear;
            }
            & p {
                font-family: "Gilroy";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 96.2%;
                color: #ffffff;
            }
        }
        &-input {
            display: none;
        }
        &-a {
            &::after {
                opacity: 1;
            }
            &::before {
                background: #cfb28b;
            }
        }
    }
}
.placeSearch {
    &__header {
        display: flex;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 19px 0px;
    }
}
// @media catalog

// @media (min-height: 700px) {
// 	.catalog {
// 		height: 100vh;
// 	}
// }

// media

@media (max-width: 360px) {
    .catalog__item-content {
        width: 155px;
    }
    .catalog__item-btn {
        width: 105px;
    }
}
