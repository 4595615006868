.placePreview {
    overflow: hidden;
    width: 100%;
    height: 140px;
    background: #2f3337;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 2fr 3.5fr;
    position: relative;
    transition: 1s all;
    &.type{
        height: 170px;
    }
    &__img {
        width: 100%;
        height: inherit;
        & img {
            border-radius: 10px 0 0 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-content {
        padding: 10px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        & > :not(:last-child) {
            padding-bottom: 5px;
        }
        &__descr {
            max-height: 32px;
        }
    }
    &__title {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    &__categs {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: max-content;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
    &__categ {
        font-family: "Gilroy";
        font-style: normal;
        color: #ffffff;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        &:last-child {
            margin: 0 0 0 0;
        }
    }
    &-menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &__img {
            width: 24px;
            height: 24px;
        }
        & > :not(:last-child) {
            margin-right: 22px;
        }
    }
    &__btn {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 60%;
        padding: 8px 18px;
        background: transparent;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #cfb28b;
        border: 1px solid #cfb28b;
        border-radius: 8px;
        cursor: pointer;
    }
}
