.cities {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
    box-sizing: border-box;

    &__title {
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        text-align: center;
        width: 90%;
        margin: 0 auto;
    }
    &__list {
        margin: 24px 0 0 0;
        justify-items: center;
        display: inline-grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        box-sizing: border-box;
    }
    &__item {
        background: #2f3337;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 17px 19px;
        width: 100%;
        max-height: 200px;
        max-width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        border: none;
        outline: none;
    }
    &__icon {
        height: 126px;
        width: 126px;
        margin-bottom: 17px;
    }
    &__city-title {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
    }
    &-aClub {
        display: flex;
        margin: 25px 0px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 360px) {
    .cities__list {
        gap: 12px;
    }
    .cities__item {
        max-height: 180px;
        max-width: 144px;
    }
    .cities__icon {
        height: 113px;
        width: 113px;
    }
    .cities__city-title {
        font-size: 14px;
    }
}
