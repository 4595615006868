.callPage {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    align-items: center;
    justify-content: space-around;
    &__button {
        width: 100%;
        border: 1px solid #cfb28b;
        border-radius: 8px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        color: #cfb28b;
        padding: 11px 22px;
        background: transparent;
        cursor: pointer;
        outline: none;
        margin: 18px 0 0 0;
    }
}
