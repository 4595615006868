.placeDetailPage {
    &__loading {
        margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}
