.searchInput {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    &__input {
        width: 100%;
        background: #2f3337;
        border-radius: 8px;
        border: 0px;
        padding: 9px;
        padding-left: 39px;
        color: #ffffff;
        &::placeholder {
            color: #ffffff;
        }
        &-group {
            display: flex;
            position: relative;
            width: 100%;
            &__icon {
                position: absolute;
                align-items: center;
                justify-content: center;
                left: 10px;
                top: 7px;
                & > img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    &__cancel {
        display: none;
        transition: 1s all;
        cursor: pointer;
        &.active {
            display: inline;
            max-width: 60px;
            padding: 10px;
        }
    }
}
