.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &__navigate {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        &_menu{
            display: flex;
            cursor: pointer;
            & > *:not(:last-child) {
                margin-right: 8px;
            }
        }
    }
    &.reverse{
        justify-content: flex-end !important;
    }
}
