.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > :not(:last-child) {
        margin-bottom: 12px;
    }
    &__title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &-table {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 12px;
        row-gap: 12px;
        width: 100%;
    }
    &-category {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 16px;
        background: #2f3337;
        border-radius: 8px;
        max-width: 161px;
        min-height: 110px;
        & > :not(:last-child) {
            margin-bottom: 5px;
        }
    }
    &-filter {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        max-width: 361px;
        height: 54px;
        border: 1px solid #cfb28b;
        border-radius: 8px;
        &__title {
            display: inline;
            max-width: 230px;
        }
    }
    &-club {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid #cfb28b;
        border-radius: 8px;
        width: 100%;
        height: 54px;
    }
    &-option {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background: #2f3337;
        border-radius: 8px;
        height: 54px;
        padding-left: 10px;
        & > :not(:last-child) {
            margin-right: 10px;
        }
    }
}
