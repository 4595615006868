.placeLayout {
    &__sub-header {
        position: sticky;
        position: -webkit-sticky;
        width: 100%;
        top: 0;
        z-index: 2;
        background-color: #202327;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__arrow {
        position: fixed;
        width: 50px;
        height: 50px;
        right: 20px;
        bottom: 20px;
        cursor: pointer;
        &.disable {
            display: none;
        }
    }
}
