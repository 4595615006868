.selection {
    position: relative;
    width: 100%;
    background: #202327;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    &__title {
        margin: 12px 0 0 0;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        width: 100%;
    }
    &__list {
        margin-top: 24px;
        margin-bottom: 44px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: inherit;
        overflow-x: hidden;
        width: 100%;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
    &__item {
        padding: 10px;
        width: -webkit-fill-available;
        background: #2f3337;
        border-radius: 3px;
        margin: 0 0 8px 0;
        width: 100%;
        &:last-child {
            margin: 0 0 0 0;
        }
    }
    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0 0 36px;
        position: relative;
        cursor: pointer;
        &::before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            background: #2f3337;
            border: 1px solid #8d9196;
            border-radius: 3px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: background 0.1s linear, border 0.1s linear;
        }
        &::after {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            background: url("../../icon/selection/check.svg") no-repeat center;
            background-size: 12px 12px;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #8d9196;
            z-index: 2;
            transition: opacity 0.1s linear;
        }
        & p {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 18px;
            color: #efeff0;
        }
    }
    &__a {
        &::after {
            opacity: 1;
        }
        &::before {
            background: #cfb28b;
        }
    }
    &__input {
        display: none;
    }
    &__test {
        position: fixed;
        left: 50%;
        bottom: 0px;
        width: 100%;
        min-height: 61px;
        max-width: 400px;
        transform: translate(-50%);
        z-index: 5;
    }
    &__btns {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
    }
    &__back {
        position: absolute;
        background-color: #202327;
        height: 100%;
        width: 110%;
        left: -5%;
    }
    &__ready {
        margin: 10px 0px;
        width: 80%;
        max-height: 41px;
        background: #cfb28b;
        border-radius: 8px;
        padding: 12px 24px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        z-index: 6;
    }
    &__random {
        border: 1px solid #cfb28b;
        border-radius: 8px;
        padding: 12px 24px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        background: transparent;
        cursor: pointer;
    }
}
// @media selection

@media (min-height: 700px) {
    .selection {
        height: 100vh;
    }
}
