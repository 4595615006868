.catalogDetail {
    width: 100%;
    background: #202327;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &__wrapper {
        padding: 12px;
        width: 100%;
        overflow: hidden;
        display: flex;
        max-width: 440px;
        margin: 0 auto;
        padding: 0 0 15px 0;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }
    &__containers{
        padding: 0px 10px;
        max-width: 440px;
        width: 100%;
        box-sizing: border-box;
    }
    &__backs {
        position: absolute;
        top: 20px;
        left: 20px;
        opacity: 1;
        z-index: 3;
        cursor: pointer;
    }
    &__wish {
        position: absolute;
        height: 24px;
        width: 24px;
        top: 20px;
        right: 20px;
        opacity: 1;
        z-index: 3;
        cursor: pointer;
    }
    &__slider {
        height: 342px;
        width: 100%;
        position: relative;
        z-index: 1;
        & .swiper-pagination {
            z-index: 10;
        }
        & .swiper {
            height: 100%;
            & .swiper-slide {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 90px;
                    top: 0;
                    left: 0;
                    background: linear-gradient(
                        180deg,
                        #363636 0%,
                        rgba(54, 54, 54, 0) 100%
                    );

                    opacity: 0.8;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 230px;
                    bottom: 0;
                    left: 0;
                    opacity: 0.9;
                    transform: rotate(180deg);
                    background: linear-gradient(
                        180deg,
                        rgb(32, 35, 39) 0%,
                        rgba(32, 35, 39, 0.93) 60.53%,
                        rgba(32, 35, 39, 0) 100%
                    );
                    z-index: 3;
                }
                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        & .swiper-pagination-bullet {
            width: 42px;
            height: 4px;
            background: #5d5d5e;
            border-radius: 11px;
        }
        & .swiper-pagination-bullet-active {
            width: 42px;
            height: 4px;
            background: #cfb28b;
            border-radius: 11px;
        }
    }
    &__header {
        position: absolute;
        pointer-events: none;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 100%;
        &-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 90%;
            padding: 20px;
        }
        &-content {
            pointer-events: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0px 20px 50px 20px;
            position: relative;
            z-index: 1;
        }
    }
    &__title {
        margin: 5px 0 0 0;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    &__categ {
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        position: relative;
        padding: 0 15px 0 0;
        margin: 0 10px 0 0;
    }
    &__description {
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        margin: 10px 0 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__desc {
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        margin: 15px 0 0 0;
    }
    &__accordions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 30px 0 0 0;
        width: 100%;
        cursor: pointer;
    }
    &__accordion {
        width: 100%;
        &Elem{
            padding: 23px 0 15px 0;
            width: 100%;
            border-top: 1px solid #2f3337;
            &:last-child {
                border-bottom: 1px solid #2f3337;
            }
            &.a {
                & .catalogDetail__accordion-content {
                    max-height: 0px;
                    transition: max-height 0.5s ease-out;
                }
                & .catalogDetail__accordion-icon {
                    transform: rotate(0deg);
                }
            }
        }
        &-icon {
            transform: rotate(45deg);
            transition: transform 0.5s ease;
        }
        &Header {
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: 0 0 15px 0;
        }
        & h5 {
            font-family: "Gilroy";
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: #ffffff;
        }
        &-content {
            pointer-events: none;
            overflow: hidden;
            max-height: 5000px;
            margin: 0 auto 0 0;
            transition: max-height 1s ease-in-out;
            & p {
                font-family: "Gilroy";
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }
    &__info {
        margin: 20px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        &-address {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            & address {
                font-family: "Gilroy";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                margin: 0 0 0 20px;
            }
        }
        &-link {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 0 34px;
            & p {
                font-family: "Gilroy";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #cfb28b;
                margin: 0 7px 0 0;
            }
        }
        &-steat {
            margin: 15px 0 0 0;
            padding: 0 0 0 34px;
            position: relative;
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                transform: translate(0, -50%);
                background: #78c572;
            }
        }
        &-website {
            margin: 15px 0 0 0;
            padding: 0 0 0 34px;
            position: relative;
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                transform: translate(0, -50%);
                background: transparent;
                border: 1px solid #fff;
            }
        }
        &-phone {
            margin: 15px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            & p {
                font-family: "Gilroy";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                margin: 0 0 0 20px;
            }
        }
    }
    &__banner {
        position: relative;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        border-radius: 15px;
        object-fit: cover;
        padding: 0px 10px;
        margin: 20px 0px 10px 0px;
        cursor: pointer;
        & > img {
            flex: 1 1 auto;
            max-width: 100%;
            height: auto;
        }
    }
    &__btns {
        bottom: 0px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
        width: 100%;
    }
    &__call {
        border: 1px solid #cfb28b;
        border-radius: 8px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        color: #cfb28b;
        padding: 11px 22px;
        background: transparent;
        cursor: pointer;
        outline: none;
        margin: 18px 0 0 0;
    }
    &__share {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #cfb28b;
        border-radius: 8px;
        padding: 11px 22px;
        border: none;
        outline: none;
        & p {
            margin: 0 6px 0 0;
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 17px;
            text-align: center;
            color: #ffffff;
        }
    }
}
.swiper {
    &__slide {
        -webkit-transform: translateZ(0);
    }
}
