.catalog {
    &__nav {
        margin: 22px 0 0 0;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
            width: 0;
        }
        & > *:not(:last-child){
            margin-right: 8px;
        }
        &-item {
            padding: 3px 14px;
            background: #cfb28b;
            border-radius: 14px;
            margin: 0 8px 0 0;
            &:last-child {
                margin: 0 0 0 0;
            }
        }
    }
    &__input {
        display: none;
    }
    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        cursor: pointer;
        width: max-content;
        & p {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: #ffffff;
        }
        &-active {
            display: flex;
            flex-direction: row;
            padding-left: 36px;
            position: relative;
            align-items: center;
            &::before {
                content: "";
                margin-left: 17px;
                display: block;
                width: 14px;
                height: 11px;
                background: url("../../icon/catalog/close.svg") no-repeat;
                background-size: 14px 11px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                z-index: 2;
                cursor: pointer;
            }
        }
        &-deactive {
            background: #202327;
            border: #cfb28b 1px solid;
            & p {
                color: #cfb28b;
            }
        }
        & p {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: #ffffff;
        }
    }
}
