body {
    margin: 0;
    font-family: "Gilroy";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #202327;
    color: #ffffff;
}
.containers {
    padding: 17px 20px;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}
.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    &.s {
        font-weight: 700;
        font-size: 14px;
        line-height: 96.2%;
    }
}
.text {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    &.s {
        font-size: 14px;
        line-height: 16px;
    }
}
.light {
    font-weight: 300 !important;
}
.normal {
    font-weight: 400 !important;
}
.regular {
    font-weight: 500 !important;
}
.semiBold {
    font-weight: 600 !important;
}
.grey {
    color: #8d9196 !important;
}
.gold {
    color: #cfb28b !important;
}
.error__text {
    margin: 30px 0px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 386px) {
    .title {
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        &.s {
            font-weight: 700;
            font-size: 11px;
            line-height: 96.2%;
        }
    }
}
