.placeList {
    position: relative;
    margin: 23px 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 17px;
    width: 100%;
    margin-bottom: 100px;
    &::after {
        content: "";
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 90px;
        background: linear-gradient(
            rgba(32, 35, 39, 0),
            rgba(32, 35, 39, 0.8),
            rgba(32, 35, 39, 1)
        );
        pointer-events: none;
    }
}
